import { useEffect, useState } from 'react';
import { Dialog } from '../feedback/Dialog';
import { Stack } from '../layout/Stack';
import { TextField } from '../inputs/TextField';
import { ContactReason, PortalAccessRequest } from '../../api/data-contracts';
import { Select, SelectItem } from '../inputs/Select';
import { ButtonGroup } from '../inputs/ButtonGroup';
import { Button } from '../inputs/Button';
import { submitAccessRequest } from '../../api/AccessRequest';
import { useToasts, ToastVariant } from '../feedback/Toast';
import { extractErrorMessage } from '../../utils/error';


interface RequestAccessDialogProps {
  organizationId: string;
  open: boolean;
  onOpenChange(open: boolean): void;
}

export const RequestAccessDialog = ({
  organizationId,
  open,
  onOpenChange,
}: RequestAccessDialogProps) => {
  const { showToast } = useToasts();

  const [contactName, setContactName] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>();
  const [reason, setReason] = useState<ContactReason>();
  const [position, setPosition] = useState<string>();

  const handleAccessRequest = (event) => {
    event.preventDefault();

    const accessRequest: PortalAccessRequest = {
      contact_name: contactName,
      company_name: companyName,
      email_address: emailAddress,
      reason: reason,
      position: position,
      organization_id: organizationId
    }

    submitAccessRequest(accessRequest)
      .then((response) => {
        onOpenChange(false);
        showToast({
          title: `Access requested, we'll email you soon.`,
          variant: ToastVariant.SUCCESS,
        });
      }).catch((error) => {
        showToast({
          title: `Couldn't request access.` + extractErrorMessage(error),
          variant: ToastVariant.ERROR,
        });
      });
    }

  return (
    <Dialog
      title="Request access"
      message="Request access to private resources."
      open={open}
      onOpenChange={onOpenChange}
    >
      <Stack spacing="medium">
        <TextField
          value={contactName}
          onChange={(event) => setContactName(event.target.value)}
          label="Contact name"
          placeholder="Jane Doe"
        />
        <TextField
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
          label="Company name"
          placeholder="Acme Inc."
        />
        <TextField
          value={emailAddress}
          onChange={(event) => setEmailAddress(event.target.value)}
          label="Email address"
          type="email"
          placeholder="security@acme.com"
        />
        <TextField
          value={position}
          onChange={(event) => setPosition(event.target.value)}
          label="Role"
          placeholder="Head of Security"
        />
        <Select
          value={reason}
          onValueChange={(value) => setReason(value as ContactReason)}
          label="Reason"
          placeholder="Select a reason">
          <SelectItem value="EXISTING_CUSTOMER">I'm an existing customer</SelectItem>
          <SelectItem value="PROSPECTIVE_CUSTOMER">I'm interested in purchasing this product</SelectItem>
          <SelectItem value="OTHER">Other</SelectItem>
        </Select>
      </Stack>
      <ButtonGroup align="right">
        <Button
          as="button"
          variant="secondary"
          onClick={() => {
            onOpenChange(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          as="button"
          variant="primary"
          onClick={handleAccessRequest}
        >
          Request access
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};