import Link from 'next/link';

interface Props {
  title: string;
  link: string;
}

export const MarketingBanner = ({ title, link }: Props) => {
  return (
    <div className="flex justify-center w-full">
      <Link
        href={link}
        className="inline-flex flex-col md:flex-row items-center text-center md:text-left gap-1 p-3 rounded-xl md:rounded-full bg-fog-50/70 bg-noise-light border border-plum-300/30 group hover:shadow-md transition-shadow focus-visible:outline outline-2 outline-offset-2 outline-plum-500"
      >
        <p className="font-sans text-xs font-[540] uppercase text-bloodmoon-600 mx-1 my-0.5">
          New!
        </p>
        <div className="font-sans text-sm font-[540] text-plum-950 flex items-center gap-2">
          {title}
        </div>
      </Link>
    </div>
  );
};