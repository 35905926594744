import {
  ChangeEventHandler,
  ComponentProps,
  HTMLInputTypeAttribute,
  ReactNode,
  useId,
} from 'react';
import { styled } from '../../stitches.config';
import { Text } from '../typography/Text';

const TextFieldContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$small',
});

const TextFieldControlContainer = styled('div', {
  position: 'relative',
  display: 'flex',
});

const TextFieldRightAction = styled('div', {
  position: 'absolute',
  right: 0,
  top: 0,
  height: '100%',
  display: 'flex',
});

const TextFieldControl = styled('input', {
  WebkitAppearance: 'none',
  height: '$space$small',
  boxSizing: 'content-box',
  flex: '1 1 0',
  color: '$silvermist-950',
  padding: '$medium',
  fontSize: '$small',
  lineHeight: '$small',
  resize: 'none',
  outline: 'none',
  background: '$white',
  transition: 'box-shadow $medium ease',
  boxShadow:
    'inset 0px 0px 0px 1px $colors$silvermist-100, $elevation-small, inset 0px -1px 1px $colors$silvermist-200',
  borderRadius: '$default',
  '&:not(:disabled):hover': {
    boxShadow: 'inset 0px 0px 0px 1px $colors$silvermist-700, $elevation-small',
  },
  '&:not(:disabled):focus': {
    boxShadow:
      'inset 0px 0px 0px 2px $colors$silvermist-700, $elevation-small, inset 0 0 transparent, 0px 0px 0px 4px $colors$silvermist-100',
  },
  '&::placeholder': {
    color: '$silvermist-500',
  },
  '&:disabled': {
    cursor: 'not-allowed',
    background: '$silvermist-50',
  },
  variants: {
    rightAction: {
      true: {
        paddingRight: '$xxxlarge',
      },
    },
    readOnly: {
      true: {
        background: '$silvermist-100',
      },
    },
  },
});

export interface TextFieldProps {
  label: string;
  css?: ComponentProps<typeof TextFieldControl>['css'];
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  defaultValue?: string;
  type?: HTMLInputTypeAttribute;
  rightAction?: ReactNode;
  readOnly?: boolean;
  helperContent?: ReactNode;
  errorMessage?: string;
  disabled?: boolean;
}

export const TextField = ({
  label,
  css,
  value,
  onChange,
  placeholder,
  defaultValue,
  type,
  rightAction,
  readOnly,
  helperContent,
  errorMessage,
  disabled,
}: TextFieldProps) => {
  const inputId = useId();
  const descriptionId = useId();
  const hasHelperContent = !!errorMessage || !!helperContent;

  return (
    <TextFieldContainer>
      <Text
        as="label"
        size="small"
        color="secondary"
        weight="medium"
        htmlFor={inputId}
      >
        {label}
      </Text>
      <TextFieldControlContainer>
        <TextFieldControl
          css={css}
          id={inputId}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          type={type}
          rightAction={!!rightAction}
          readOnly={readOnly}
          aria-describedby={hasHelperContent ? descriptionId : undefined}
          disabled={disabled}
        />
        {rightAction ? (
          <TextFieldRightAction>{rightAction}</TextFieldRightAction>
        ) : null}
      </TextFieldControlContainer>
      {hasHelperContent ? (
        <Text
          size="xsmall"
          color={errorMessage ? 'error' : 'secondary'}
          id={descriptionId}
        >
          {errorMessage ?? helperContent}
        </Text>
      ) : null}
    </TextFieldContainer>
  );
};
