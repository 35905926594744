import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import * as SelectPrimitive from '@radix-ui/react-select';
import {
  SelectItemProps as SelectItemPrimitiveProps,
  SelectProps as SelectPrimitiveProps,
} from '@radix-ui/react-select';
import React, { useId } from 'react';
import { styled } from '../../stitches.config';
import { Text } from '../typography/Text';

const SelectContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$small',
});

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  height: '$space$small',
  boxSizing: 'content-box',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$default',
  padding: '$medium',
  fontSize: '$small',
  lineHeight: '$small',
  gap: '$xsmall',
  background: '$white',
  color: '$silvermist-950',
  cursor: 'pointer',
  transition: 'box-shadow $medium ease',
  boxShadow:
    'inset 0px 0px 0px 1px $colors$silvermist-100, $elevation-small, inset 0px -1px 1px $colors$silvermist-200',
  '&:hover': {
    boxShadow: 'inset 0px 0px 0px 1px $colors$silvermist-700, $elevation-small',
  },
  '&:focus': {
    boxShadow:
      'inset 0px 0px 0px 2px $colors$silvermist-700, $elevation-small, inset 0 0 transparent, 0px 0px 0px 4px $colors$silvermist-100',
  },
  '&[data-placeholder]': {
    color: '$silvermist-500',
  },
});

const StyledIcon = styled(SelectPrimitive.SelectIcon, {
  color: '$silvermist-950',
  fontSize: '$space$medium',
});

const StyledContent = styled(SelectPrimitive.Content, {
  padding: 0,
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: '$small',
  boxShadow: '$elevation-large, inset 0 0 0 1px $colors$silvermist-100',
  zIndex: 10,
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: '$xsmall',
});

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  color: '$silvermist-950',
  borderRadius: '$small',
  display: 'flex',
  alignItems: 'center',
  padding: '$small $small $small $large',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  '&[data-disabled]': {
    color: '$silvermist-500',
    pointerEvents: 'none',
  },
  '&[data-highlighted]': {
    backgroundColor: '$silvermist-50',
  },
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$space$medium',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: '$white',
  color: '$lupine-600',
  cursor: 'default',
  fontSize: '$space$medium',
};

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles,
);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles,
);

interface SelectProps extends SelectPrimitiveProps {
  label?: string;
  children: React.ReactNode;
  placeholder?: string;
  autoFocus?: boolean;
}

export const Select = React.forwardRef<HTMLButtonElement, SelectProps>(
  (
    { label, children, placeholder, autoFocus, ...props },
    forwardedRef: React.Ref<HTMLButtonElement>,
  ) => {
    const selectId = useId();

    return (
      <SelectContainer>
        {
          label && (
            <Text
              as="label"
              size="small"
              color="secondary"
              weight="medium"
              htmlFor={selectId}
            >
              {label}
            </Text>
          )
        }
        <SelectPrimitive.Root {...props}>
          <StyledTrigger ref={forwardedRef} id={selectId} autoFocus={autoFocus}>
            <SelectPrimitive.Value placeholder={placeholder} />
            <StyledIcon>
              <ChevronDownIcon width="1em" height="1em" />
            </StyledIcon>
          </StyledTrigger>
          <SelectPrimitive.Portal>
            <StyledContent onCloseAutoFocus={(event) => event.preventDefault()}>
              <StyledScrollUpButton>
                <ChevronUpIcon width="1em" height="1em" />
              </StyledScrollUpButton>
              <StyledViewport>{children}</StyledViewport>
              <StyledScrollDownButton>
                <ChevronDownIcon width="1em" height="1em" />
              </StyledScrollDownButton>
            </StyledContent>
          </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
      </SelectContainer>
    );
  },
);

interface SelectItemProps extends SelectItemPrimitiveProps {
  children: React.ReactNode;
}

export const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, ...props }, forwardedRef: React.Ref<HTMLDivElement>) => {
    return (
      <StyledItem {...props} ref={forwardedRef}>
        <SelectPrimitive.ItemText>
          <Text size="small" color="inherit" weight="medium">
            {children}
          </Text>
        </SelectPrimitive.ItemText>
        <StyledItemIndicator>
          <CheckIcon width="1em" height="1em" />
        </StyledItemIndicator>
      </StyledItem>
    );
  },
);
