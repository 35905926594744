import {XMarkIcon} from '@heroicons/react/24/outline';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import React, {useEffect} from 'react';
import {keyframes, styled} from '../../stitches.config';
import {Stack} from '../layout/Stack';
import {Text} from '../typography/Text';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translateY(100px) scale(.8)' },
  '100%': { opacity: 1, transform: 'translateY(0px) scale(1)' },
});

const DialogOverlay = styled(DialogPrimitive.Overlay, {
  position: 'fixed',
  inset: 0,
  paddingVertical: '$xxxxxlarge',
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',
  justifyItems: 'center',
  overflowX: 'scroll',
  zIndex: 3,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} $transitions$slow cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:before': {
    content: '',
    position: 'fixed',
    inset: 0,
    backgroundColor: '$silvermist-800',
    opacity: 0.8,
  },
});

const DialogContent = styled(DialogPrimitive.Content, {
  backgroundColor: '$white',
  borderRadius: '$default',
  boxShadow: '$elevation-large',
  width: '90vw',
  boxSizing: 'border-box',
  maxWidth: '800px',
  padding: '$large',
  position: 'relative',
  border: '1px solid $silvermist-100',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} $transitions$slow cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': { outline: 'none' },
});

const DialogTitle = DialogPrimitive.Title;

const TitleContainer = styled('div', {
  paddingRight: '$xlarge',
});

const DialogDescription = DialogPrimitive.Description;

const DialogClose = styled(DialogPrimitive.Close, {
  all: 'unset',
  padding: '$small',
  borderRadius: '$round',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$silvermist-950',
  position: 'absolute',
  top: '$xsmall',
  right: '$xsmall',
  cursor: 'pointer',
  transition:
    'background $medium ease, box-shadow $medium ease, color $medium ease',
  fontSize: '$space$large',
  '&:hover': {
    background: '$bloodmoon-50',
    color: '$bloodmoon-600',
  },
  '&:focus-visible': {
    boxShadow: '0 0 0 2px $colors$bloodmoon-600',
    color: '$bloodmoon-600',
  },
});

export function Dialog({
  open,
  onOpenChange,
  title,
  message,
  children,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  message?: string;
  children: React.ReactNode;
}) {
  // When nested components also trap focus (eg: Select) this style isn't properly clear.
  // This is a hack to make sure the content is interactive after dismissing.
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        document.body.setAttribute('style', 'pointer-events:auto !important');
      }, 1);
    }
  }, [open]);

  return (
    <DialogPrimitive.Root open={open} onOpenChange={onOpenChange}>
      <DialogPrimitive.Portal>
        <DialogOverlay>
          <DialogContent>
            <Stack spacing="large">
              <TitleContainer>
                <DialogTitle asChild>
                  <Text as="h2" size="large" weight="semibold">
                    {title}
                  </Text>
                </DialogTitle>
              </TitleContainer>
              {message ? (
                <DialogDescription asChild>
                  <Text>{message}</Text>
                </DialogDescription>
              ) : null}
              {children}
            </Stack>
            <DialogClose aria-label="Close">
              <XMarkIcon width="1em" height="1em" />
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
}
