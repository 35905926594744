/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosRequestConfig } from 'axios';
import { api } from './';
import { PortalAccessRequest, Response } from './data-contracts';

/**
 * Submit a request for access to the portal.
 */
export const submitAccessRequest = (
  data: PortalAccessRequest,
  config?: AxiosRequestConfig
) => api.post<Response>(`/access-request`, data, config);
